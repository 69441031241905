import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "templateKey": "sv-about",
  "title": "Om oss",
  "seo": {
    "title": "Om oss",
    "descr": "-"
  },
  "hero": {
    "title": "Om oss",
    "text": "Zimply är ett innovationsbolag som digitaliserar, automatiserar och optimerar processer med hjälp av Intelligent Automation. Här kombineras spetskompetenser inom Intelligent Automation och AI för att hitta innovativa och skräddarsydda lösningar till våra kunder. Våra lösningar inom automatisering effektiviserar och framtidssäkrar verksamheter, det kan ske i allt från AI-strategier till digitala medarbetare som frigör tid och befriar anställda från repetitiva och monotona arbetsuppgifter. Med kundens affärsmässiga intressen i fokus frigör vi värdefull tid genom tekniska lösningar. Zimplys tvärfunktionella team och produktoberoende möjliggör behovsanpassade lösningar.",
    "bg": "/img/bg-about.jpg",
    "heroTextList": [{
      "text": "Zimply är ett innovationsbolag som digitaliserar, automatiserar och optimerar processer med hjälp av Intelligent Automation. Här kombineras spetskompetenser inom Intelligent Automation och AI för att hitta innovativa och skräddarsydda lösningar till våra kunder."
    }, {
      "text": "Våra lösningar inom automatisering effektiviserar och framtidssäkrar verksamheter, det kan ske i allt från AI-strategier till digitala medarbetare som frigör tid och befriar anställda från repetitiva och monotona arbetsuppgifter. Med kundens affärsmässiga intressen i fokus frigör vi värdefull tid genom tekniska lösningar. Zimplys tvärfunktionella team och produktoberoende möjliggör behovsanpassade lösningar."
    }]
  },
  "content": [{
    "type": "simple",
    "simple": [{
      "bgColor": "#f8f8f8",
      "title": "Vårt team",
      "text": "asegsreg",
      "textSection": [{
        "text": "Vi som arbetar på Zimply innovation är problemlösare med ett gemensamt intresse, att skapa värde med hjälp av banbrytande teknologi. Vi har lång erfarenhet av Intelligent Automation, RPA och Artificiell Intelligens. Tillsammans har vi levererat automationslösningar till kunder inom flera affärsfunktioner och branscher. För oss är det viktigt att alltid vara nyfikna och passionerade med ett fokus på kvalitet och affärsnytta.",
        "boldText": "-"
      }, {
        "text": "Våra utvecklare är certifierade inom de olika verktygen vi använder. Inom RPA är vi certifierade i Blue Prism (Developer och Professional Developer), UiPath (Advanced), Pega Robotics och Kofax Kapow.",
        "boldText": "-"
      }],
      "buttonText": "Vårt team",
      "buttonPath": "team",
      "titleIcon": [{
        "icon": "/img/null.png"
      }],
      "iconOverTitle": "/img/null.png"
    }]
  }, {
    "type": "full width image",
    "image": [{
      "image": "/img/office2.webp",
      "title": "-",
      "text": "-",
      "height": 0,
      "overlay": false
    }]
  }, {
    "type": "points",
    "typePoints": [{
      "title": "Våra värderingar",
      "points": [{
        "icon": "/img/hand.png",
        "text": "Vi har hög kompetens inom många olika områden och branscher vilket syns i våra leveranser. Våra sammanslagna erfarenheter och kunskaper genomsyrar allt vi gör vilket bidrar till hög kvalitet.",
        "title": "Kvalitet"
      }, {
        "icon": "/img/lightbulb.png",
        "text": "Vår bransch förändras ständigt och höga krav ställs på oss. Därför är vi kreativa och flexibla för att kunna möta våra kunders behov. Vi ligger i framkant och är nyfikna på hur marknaden kommer att utvecklas.",
        "title": "Kreativitet"
      }, {
        "icon": "/img/heart.png",
        "text": "Vi är ett sammansvetsat team som tycker att det vi gör är kul. Vi är inkluderande och engagerade och vi drivs av att lära oss nya saker och utmanas för att utvecklas tillsammans.",
        "title": "Passion"
      }]
    }]
  }, {
    "type": "full width image",
    "image": [{
      "image": "/img/bg-glasses.jpg",
      "title": "Vision",
      "text": "Vi motiveras av att vara i framkant av den tekniska utvecklingen och att få vara med att skapa roligare arbete, nå affärsmål och förbättra kundupplevelsen. Vi tror på teknikutvecklingens positiva möjligheter och ser våra intelligenta lösningar som en katalysator för mer hållbara organisationer och gladare medarbetare.",
      "height": 150,
      "overlay": true,
      "textBlocks": [{
        "text": "Vi motiveras av att vara i framkant av den tekniska utvecklingen och att få vara med att skapa roligare arbete, nå affärsmål och förbättra kundupplevelsen. Vi tror på teknikutvecklingens positiva möjligheter och ser våra intelligenta lösningar som en katalysator för mer hållbara organisationer och gladare medarbetare."
      }]
    }]
  }, {
    "type": "simple",
    "simple": [{
      "bgColor": "#fff",
      "title": "Digital transformation",
      "buttonText": "-",
      "buttonPath": "-",
      "textSection": [{
        "text": "Digitala världen förändras snabbt och drivs av en ökad konkurrens. Detta gör att allt fler investerar i nya teknologier som RPA. RPA möjliggör automation av repetitiva processer med högt affärsvärde för organisationer. Låt våra lyhörda specialister hjälpa er att börja automatisera delar av er verksamhet som ett nästa steg i den digitala transformationen.",
        "boldText": "-"
      }],
      "titleIcon": [{
        "icon": "/img/null.png"
      }],
      "iconOverTitle": "/img/null.png"
    }]
  }, {
    "type": "simple",
    "simple": [{
      "title": "Strategi och återgivning",
      "bgColor": "#f8f8f8",
      "textSection": [{
        "text": "Med vår långa erfarenhet och breda kompetens kan vi ta fram en välfungerande, effektiv och långsiktigt hållbar strategi för att automatisera era manuella processer. Vi gör lönsamhetsanalyser och prognoser för era automationsprojekt och tar fram de mest relevanta nyckeltalen för effektiv uppföljning. Om ni redan har en strategi hjälper vi er att realisera den. Vi jobbar nära och tillsammans med er för att omvandla er strategi till resultat.",
        "boldText": "-"
      }, {
        "text": "Vi kan även hjälpa er att utforma och driftsätta rätt arbetsmodell samt att definiera en skalbar plan. Detta genom att integrera Intelligent Automation i verksamheten samt utveckla och bygga upp ert interna kompetenscenter.",
        "boldText": "-"
      }],
      "buttonText": "-",
      "buttonPath": "-",
      "titleIcon": [{
        "icon": "/img/null.png"
      }],
      "iconOverTitle": "/img/null.png"
    }]
  }, {
    "type": "full width image",
    "image": [{
      "title": "-",
      "text": "-",
      "image": "/img/bg-office4.jpg",
      "height": 200,
      "overlay": false
    }]
  }, {
    "type": "simple",
    "simple": [{
      "title": "Kravställning, analys och utveckling",
      "buttonText": "-",
      "buttonPath": "-",
      "textSection": [{
        "text": "Vi utför en processutvärdering för att identifiera processer som har bäst potential för en Digital Assistent och Intelligent Automation. Vi tar fram ett Business Case, ett beslutsunderlag som visualiserar fördelarna av att automatisera verksamhetsprocesser. Har ni ännu inte bestämt er för ett verktyg så analyserar vi er verksamhet och föreslår det bäst lämpade verktyget.",
        "boldText": "-"
      }, {
        "text": "Våra erfarna och certifierade kravanalytiker och utvecklare jobbar nära er verksamhet för att kravställa och utveckla process automationen på ett optimalt sätt och leverera värde till er kontinuerligt och i ett så tidigt skede som möjligt. Utöver detta så testas och dokumenteras våra Digitala Assistenter noggrant innan de implementeras.",
        "boldText": "-"
      }],
      "bgColor": "-",
      "titleIcon": [{
        "icon": "/img/null.png"
      }],
      "iconOverTitle": "/img/null.png"
    }]
  }, {
    "type": "simple",
    "simple": [{
      "title": "Förvaltning & support",
      "bgColor": "#f6f6f6",
      "textSection": [{
        "text": "När ni hyr digitala assistenter från Zimply så ingår förvaltning och support. Ni kan luta er tillbaka och låta oss göra jobbet, vi ser till att era Digitala Assistenter alltid jobbar enligt plan. Det betyder att ni kan fokusera på er verksamhet och det ni kan bäst.",
        "boldText": "-"
      }, {
        "text": "Förvaltningen kvalitetssäkrar processerautomationerna och de digitala assistenterna genom att granska och utvärdera dem innan de implementeras i produktion. Vi har även egna Robotar som kan hjälpa till löpande med att granska processer och verifiera att dessa följer ”Best Practice”, vilket underlättar inför de ska implementeras.",
        "boldText": "Kvalitet"
      }, {
        "text": "Förvaltningen säkerställer att de Digitala Assistenterna är tillgängliga och att Processer körs enligt scheman. Vi tar helhetsansvaret och sköter driften av era Digitala Assistenterna genom att övervaka och utföra kontinuerligt underhåll.",
        "boldText": "Drift och Underhåll"
      }, {
        "text": "Vi hjälper er att säkra den enorma potentialen med Intelligent Automation genom att kvalitetssäkra processer och kontinuerligt förbättra era digitala medarbetare. Det bidrar till ökad effektivitet och produktivitet.",
        "boldText": "Löpande förbättringar"
      }, {
        "text": "Vi hyr ut digitala assistenter genom månadsabonnemang, men är flexibla genom att erbjuda den prismodell som passar er bäst. Kontakta oss för att lära er mer.",
        "boldText": "Kostnad"
      }, {
        "text": "Vi utbildar er organisation om robotar och Intelligent Automation samt förbereder er på er resa med de digitala medarbetarna.",
        "boldText": "Utbildning"
      }],
      "buttonText": "-",
      "buttonPath": "-",
      "titleIcon": [{
        "icon": "/img/null.png"
      }],
      "iconOverTitle": "/img/null.png"
    }]
  }, {
    "type": "simple",
    "typePoints": [],
    "simple": [{
      "bgColor": "-",
      "title": "Robots-as-a-Service",
      "buttonText": "-",
      "buttonPath": "-",
      "textSection": [{
        "text": "Kom snabbt igång med er automatisering. Zimply Innovation kommer att tillhandahålla hela end-to-end tjänsten och erbjuder ett helhetsgrepp med vårt erfarna Intelligent Automation team",
        "boldText": "Enkel implementation"
      }, {
        "boldText": "Moln",
        "text": "Vi skapar Digitala Assistenter genom en skalbar och molnbaserad tjänst."
      }, {
        "boldText": "Uppnå kostnadsfördelar på kortare tid",
        "text": "Vi tillhandahåller system, mjukvara och team, därför kan vi snabbt få igång era robotar anpassade efter era behov. Enkelt, effektivt och lönsamt."
      }, {
        "boldText": "Reducerad risk",
        "text": "Vi erbjuder drift av miljö, mjukvara och förvaltning i ett och samma paket med månadshyra. Omfattningen av tjänsten baseras på överenskommet SLA (Service Level Agreement)."
      }],
      "titleIcon": [{
        "icon": "/img/arrow.png"
      }],
      "iconOverTitle": "/img/cloud.png"
    }]
  }, {
    "type": "full width image",
    "typePoints": [],
    "simple": [],
    "image": [{
      "image": "/img/mindmap.jpg",
      "title": "Fördelar med automatisering",
      "text": "-",
      "height": 250,
      "overlay": true,
      "textBlocks": [],
      "checkTextBlocks": [{
        "text": "Frigör tid att förnya, utveckla och fokusera på kundtillfredsställelse"
      }, {
        "text": "Kostnadsreducering"
      }, {
        "text": "Snabb ROI"
      }, {
        "text": "Ökad och jämnare kvalitet"
      }, {
        "text": "Minskat antal fel samtidigt som effektiviteten ökar"
      }, {
        "text": "Flexibilitet och skalbarhet"
      }]
    }]
  }],
  "pink": {
    "title": "Kom igång",
    "text": "Investera i framtiden och hitta er Digitala Assistent. Följ med in i en värld av nya tekniska möjligheter, skräddarsydda efter era behov. Kontakta oss idag.",
    "buttonText": "Kontakta oss",
    "buttonPath": "contact"
  },
  "endSection": {
    "icon": "/img/cost.png",
    "buttonText": "Gå till",
    "buttonPath": "digitalassistants",
    "title": "Våra assistenter"
  }
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      